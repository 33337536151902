
export const TABLE_IDS = {
  USERS: 'm8sj23ouy6jfsnr',
  GENERAL_FEEDBACK: 'm4ep2wkxo1xoxs1',
  SOLUTION_FEEDBACK: 'mlthj26htea1pcd',
  SOLUTIONS: 'mj5e0isyx8poscz',
  INTEGRATION_FEEDBACK: 'mrb9y2e0h0etg7p'
};

export const SOLUTION_SECTIONS = {
  'Automation as a Service': {
    name: 'Automation as a Service',
    sections: {
      '1.0': 'Overview',
      '1.1': 'Business Case',
      '1.2': 'Feedback'
    }
  },
  'Expert Path': {
    name: 'Expert Path',
    sections: {
      '2.0': 'Overview',
      '2.1': 'Prototype',
      '2.2': 'Feedback'
    }
  },
  'Master Fix Database': {
    name: 'Master Fix Database',
    sections: {
      '3.0': 'Overview',
      '3.1': 'Prototype',
      '3.2': 'Feedback'
    }
  },
  'Onboarding Navigator': {
    name: 'Onboarding Navigator',
    sections: {
      '4.0': 'Overview',
      '4.1': 'Mockup',
      '4.2': 'Feedback'
    }
  },
  'Outage Hub': {
    name: 'Outage Hub',
    sections: {
      '5.0': 'Overview',
      '5.1': 'Mockup',
      '5.2': 'Feedback'
    }
  },
  'Project Path': {
    name: 'Project Path',
    sections: {
      '6.0': 'Overview',
      '6.1': 'Prototype',
      '6.2': 'Feedback'
    }
  },
  'Robotic Process Automation': {
    name: 'Robotic Process Automation',
    sections: {
      '7.0': 'Overview',
      '7.1': 'Video',
      '7.2': 'Feedback'
    }
  },
  'Workflow Orchestrator': {
    name: 'Workflow Orchestrator',
    sections: {
      '8.0': 'Overview',
      '8.1': 'Prototype',
      '8.2': 'Feedback'
    }
  }
};

export const DESKTOP_SECTIONS = {
  'Overview': {
    name: 'Overview',
    sections: {
      '1.0': 'Overview'
    }
  },
  'Legacy': {
    name: 'Legacy',
    sections: {
      '2.0': 'Legacy'
    }
  },
  'Redesign': {
    name: 'Redesign',
    sections: {
      '3.0': 'Redesign',
      '3.1': 'Home',
      '3.2': 'Knowledge',
      '3.3': 'Troubleshoot',
      '3.4': 'Library',
      '3.5': 'Tickets',
      '3.6': 'Status',
      '3.7': 'Search'
    }
  },
  'Validation': {
    name: 'Validation',
    sections: {
      '4.0': 'Validation'
    }
  },
  'Labs': {
    name: 'Labs',
    sections: {
      '5.0': 'Labs',
      '5.1': 'Bot',
      '5.2': 'Migration'
    }
  },
  'Feedback': {
    name: 'Feedback',
    sections: {
      '6.0': 'Feedback'
    }
  }
};

export const TOTAL_SOLUTION_SECTIONS = Object.values(SOLUTION_SECTIONS).reduce(
  (total, solution) => {
    const validSections = Object.keys(solution.sections).filter(id => !id.startsWith('0.'));
    return total + validSections.length;
  },
  0
);

export const TOTAL_DESKTOP_SECTIONS = Object.values(DESKTOP_SECTIONS).reduce(
  (total, category) => total + Object.keys(category.sections).length,
  0
);
