import React from 'react';
import { DollarSign, Users } from 'lucide-react';
import { CompanyInterests } from '../../types/nocodb';
import { PRICING } from '../../config/pricing';

interface DesktopInterestProps {
  companies: Array<{ name: string; interests: CompanyInterests }>;
}

export function DesktopInterest({ companies }: DesktopInterestProps) {
  const calculateMetrics = (featureName: string) => {
    const interestedClients = companies.filter(company => {
      const features = company.interests.desktop ? company.interests.desktop.split(',') : [];
      return features.some(f => f.startsWith(featureName + ':'));
    });

    const totalLicenses = interestedClients.reduce((sum, company) => {
      const features = company.interests.desktop ? company.interests.desktop.split(',') : [];
      const feature = features.find(f => f.startsWith(featureName + ':'));
      if (feature) {
        const [, count] = feature.split(':');
        return sum + (parseInt(count) || 0);
      }
      return sum;
    }, 0);

    return { interestedClients, totalLicenses };
  };

  return (
    <div className="bg-white rounded-xl shadow-sm overflow-hidden">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-lg font-semibold">Desktop Interest</h2>
      </div>
      
      <div className="divide-y divide-gray-200">
        {Object.entries(PRICING.desktop).map(([feature, pricing]) => {
          const { interestedClients, totalLicenses } = calculateMetrics(feature);
          const monthlyRevenue = pricing.price * totalLicenses;
          const annualRevenue = monthlyRevenue * 12;

          return (
            <div key={feature} className="px-6 py-4">
              <div className="flex items-center justify-between mb-2">
                <span className="font-medium text-gray-900">{feature}</span>
                <div className="flex items-center space-x-2 text-sm">
                  <Users className="w-4 h-4 text-gray-400" />
                  <span>{interestedClients.length} companies</span>
                </div>
              </div>
              
              <div className="flex items-center justify-between text-sm">
                <div className="text-gray-500">
                  {totalLicenses} PCs
                </div>
                <div className="flex items-center text-green-600 font-medium">
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumFractionDigits: 0
                  }).format(annualRevenue)}
                  <span className="text-gray-400 ml-1">/year</span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}