import React from 'react';
import { Building2, DollarSign } from 'lucide-react';
import { CompanyInterests } from '../../types/nocodb';
import { PRICING } from '../../config/pricing';

interface ProspectsProps {
  companies: Array<{ name: string; interests: CompanyInterests }>;
  onSelectCompany: (name: string) => void;
}

export function Prospects({ companies, onSelectCompany }: ProspectsProps) {
  const calculateCompanyValue = (interests: CompanyInterests) => {
    let annualValue = 0;

    // Calculate solutions value
    if (interests.solutions) {
      interests.solutions.split(',').forEach(solution => {
        const [name, count] = solution.split(':');
        const licenses = parseInt(count) || 0;
        const pricing = PRICING.solutions[name as keyof typeof PRICING.solutions];
        
        if (pricing) {
          if (pricing.type === 'annual') {
            annualValue += pricing.price;
          } else {
            annualValue += pricing.price * licenses * 12;
          }
        }
      });
    }

    // Calculate desktop value
    if (interests.desktop) {
      interests.desktop.split(',').forEach(feature => {
        const [name, count] = feature.split(':');
        const licenses = parseInt(count) || 0;
        const pricing = PRICING.desktop[name as keyof typeof PRICING.desktop];
        
        if (pricing) {
          annualValue += pricing.price * licenses * 12;
        }
      });
    }

    return annualValue;
  };

  const sortedCompanies = [...companies].sort((a, b) => {
    const valueA = calculateCompanyValue(a.interests);
    const valueB = calculateCompanyValue(b.interests);
    return valueB - valueA;
  });

  return (
    <div className="bg-white rounded-xl shadow-sm overflow-hidden">
      <div className="p-6 border-b border-gray-200">
        <h2 className="text-lg font-semibold">Prospects</h2>
      </div>
      
      <div className="divide-y divide-gray-200">
        {sortedCompanies.map(({ name, interests }) => {
          const annualValue = calculateCompanyValue(interests);
          const solutionCount = interests.solutions ? interests.solutions.split(',').length : 0;
          const desktopCount = interests.desktop ? interests.desktop.split(',').length : 0;

          return (
            <button
              key={name}
              onClick={() => onSelectCompany(name)}
              className="w-full px-6 py-4 hover:bg-gray-50 transition-colors text-left"
            >
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center space-x-3">
                  <div className="h-10 w-10 rounded-full bg-blue-100 flex items-center justify-center">
                    <Building2 className="h-5 w-5 text-blue-500" />
                  </div>
                  <span className="font-medium text-gray-900">{name}</span>
                </div>
                <div className="flex items-center text-green-600 font-medium">
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    maximumFractionDigits: 0
                  }).format(annualValue)}
                  <span className="text-gray-400 ml-1">/year</span>
                </div>
              </div>
              
              <div className="text-sm text-gray-500">
                {solutionCount} solutions, {desktopCount} desktop features
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
}